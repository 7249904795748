
export const JSESSIONID_KEY = "JSESSIONID";

const domain = process.env.VUE_APP_DOMAIN;

export function getBaseUrl() {
    // const domain = getDomain(env);
    return `https://${domain}`;
}

export function getDomain() {
    return domain;
}

export function mapRightsState(rightsState) {
    switch(rightsState) {
        case "USED":
            return "充值成功";
        case "UNUSED": 
            return "未使用";
        case "INVALID": 
        default:
            return "已失效";
    }
}